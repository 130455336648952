/* HEADING */
.section_heading {
	margin-bottom: 5rem;
	text-align: center;
}
.heading {
	color: $black;
	font: 400 3rem $font-family-base;
	margin: 0 0 1.5rem;
	text-transform: capitalize;
	strong {
		color: theme-color($key: "primary");
		font-weight: 500;
	}
	@include media-breakpoint-up(lg) {
		font-size: 4rem;
	}
}
.subheading {
	color: $gray-600;
	font: 400 $font-size-base $font-family-base;
}
/* HEADING */