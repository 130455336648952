// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-border-color-focus` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus() {
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: none;
    @include box-shadow($input-focus-box-shadow);
  }
}


@mixin form-validation-state($state, $color) {

  .form-control,
  .custom-select {
    .was-validated &:#{$state},
    &.is-#{$state} {
      border-color: $color;

      &:focus {
        box-shadow: 0 0 0 .2rem rgba($color,.25);
      }

      ~ .invalid-feedback,
      ~ .invalid-tooltip {
        display: block;
      }
    }
  }


  // TODO: redo check markup lol crap
  .form-check-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      + .form-check-label {
        color: $color;
      }
    }
  }

  // custom radios and checks
  .custom-control-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .custom-control-indicator {
        background-color: rgba($color, .25);
      }
      ~ .custom-control-description {
        color: $color;
      }
    }
  }

  // custom file
  .custom-file-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .custom-file-control {
        border-color: $color;

        &::before { border-color: inherit; }
      }
      &:focus {
        box-shadow: 0 0 0 .2rem rgba($color,.25);
      }
    }
  }
}
