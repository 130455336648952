.blog {
	border: 1px solid #f1f1f1;
	margin-bottom: 4rem;
	hr {
		margin-bottom: 1.5rem;
	}
	&_special {
		border: none;
		.blog_thumbnail {
			&:after {
				background: rgba(0,0,0,.3);
				content: "";
				left: 0;
				position: absolute;
				top: 0;
				@include size(100%, 100%);
			}
		}
		.blog_content {
			bottom: 0;
			position: absolute;
			width: 100%;
			a {
				color: $white;
			}
			.btn-link {
				color: $white;
				font-weight: 300;
				letter-spacing: .2rem;
				padding: 0;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	&_thumbnail {
		position: relative;
		img {
			width: 100%;
		}
	}
	&_meta {
		list-style: none;
		margin-bottom: 1.5rem;
		padding: 0;
		> li {
			color: $gray-700;
			font-size: 1.2rem;
			margin-right: 1.5rem;
			a {
				color: $gray-700;
			}
			.icons {
				font-size: 2rem;
				margin-right: 1rem;
				position: relative;
				top: .3rem;
			}
		}
		.meta_author {
			img {
				margin-right: 1rem;
				max-width: 3rem;
			}
		}
		@include media-breakpoint-up(lg) {
			> li {
				font-size: 1.4rem;
				margin-right: 2rem;
				.icons {
					margin-right: .5rem;
				}
			}
		}
	}
	&_content {
		padding: 3rem;
		z-index: 9;
		&_category {
			margin-bottom: 1.5rem;
			position: relative;
			a {
				color: $gray-700;
				font: 300 1.2rem $font-family-serif;
				letter-spacing: .1rem;
				text-transform: uppercase;
			}
		}
		&_heading {
			margin-bottom: 1.5rem;
			color: $gray-700;
			font: 400 2rem $font-family-base;
			text-transform: capitalize;
		}

		&_desc, &_text {
			color: $gray-700;
			font: 300 1.6rem $font-family-base;
			line-height: 2.7rem;
			margin-bottom: 1.5rem;
		}
		.readmore {
			border-width: .2rem .2rem .2rem .2rem;
			font-weight: 700;
		}
	}
}

.blog_single {
	.blog {
		border: none;
		&_content {
			padding: 0;
			&_heading {
				color: $headings-color;
				font-size: 3rem;
				margin-bottom: 3rem;
			}
			h2, h3, h4, h5, h6 {
				margin: 3rem 0;
			}
		}
		&_thumbnail {
			margin: 2rem 0;
		}
	}
	hr {
		margin: 5rem 0;
	}
}