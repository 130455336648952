/* Topbar */

$topbar-bg              : $nocolor !default;
$topbar-bg-dark         : #222222;
$topbar-bg-gray         : #212121;
$topbar-link-color      : $white !default;
$topbar-link-hover-color: $link-hover-color !default;
$topbar-color           : $white !default;
$topbar-border          : 1px solid rgba(255,255,255,.1) !default;
$topbar-font-size       : 1.2rem !default;
$topbar-icon-color      : $white !default;
$topbar-icon-font-size  : 1.2rem !default;
$topbar-text-transform  : capitalize !default;
$topbar-text-margin     : 0 .5rem 0 0 !default;
$topbar-padding         : 1rem 0 !default;
$topbar-line-height     : 1.5 !default;


/* FOOTER */
$footer-bg   : #323746;
$footer-color: #8e95ac;


/* BREADCRUMBS */
$breadcrumb-active-color    : #222222;
$breadcrumb-bg              : $nocolor;
$breadcrumb-color           : $gray-300;
$breadcrumb-font-size       : 1.2rem;
$breadcrumb-font-weight     : 400;
$breadcrumb-link-color      : $gray-600;
$breadcrumb-link-hover-color: #222222;
$breadcrumb-padding         : 2rem 0;
$breadcrumb-margin          : 0 0 5rem;
$breadcrumb-text-transform  : capitalize;
