/* JUMBOTRON */

.jumbotron {
	padding: 0;
	position: relative;
	@include border-radius(0);
	.item {
		&_slide {
			display: block;
			height: 40rem;
		}
		.slide_description {
			left: 50%;
			position: absolute;
			top: 50%;
			width: 80%;
			@extend .text-white, .text-center;
			@include translate(-50%, -50%);
			.title {
				font: 400 3.6rem $font-family-sans-serif;
				margin-bottom: 1.5rem;
				text-transform: capitalize;
				@extend .text-white;
			}
			p {
				margin-bottom: 2rem;
			}
		}
		@include media-breakpoint-up(lg) {
			&_slide {
				height: 90rem;
			}
			.slide_description {
				.title {
					font-size: 7rem;
				}
				p {
					font-size: 1.6rem;
					margin-bottom: 3rem;
				}
			}
		}
	}
	&_video {
		&:after {
			background: rgba(0,0,0,.3);
			content: "";
			left: 0;
			position: absolute;
			top: 0;
			@include size(100%,100%);
		}
	}

	&_slider {
		.item {
			&_slide {
				background: url("../images/slider/1920x820.png") 50% 50% no-repeat;
				background-size: cover;
			}
		}
	}
	
	/* OWL-CAROUSEL */
	.owl-carousel {
		.owl-nav {
			> div {
				background: transparent;
				font-size: 0;
				position: absolute;
				top: 50%;
				@include translateY(-50%);
				&:after {
					display: none;
					font: 400 3.5rem 'simple-line-icons';
					@include transition(all .3s);
				}
				&.owl-prev {
					left: 10%;
					&:after {
						content: "\e07a";
					}
				}
				&.owl-next {
					right: 10%;
					&:after {
						content: "\e079";
					}
				}
				&:hover {
					background: transparent;
					&:after {
						color: $gray-300;
					}
				}
			}
		}
		@include media-breakpoint-up(lg) {
			.owl-nav {
				> div {
					&:after {
						display: block;
					}
				}
			}
		}
	}
	/* OWL-CAROUSEL */
	
	/* SLIDE-ITEM-PROPERTY */
	.item_slide_desc {
		margin   : 0 auto;
		position : relative;
		max-width: 111rem;
		width    : 100%;
		.prop_wrap {
			background: $white;
			bottom    : 5rem;
			display   : none;
			margin    : 0;
			padding   : 5rem;
			position  : absolute;
			right     : 5rem;
			width     : 100%;
			.prop_meta_wrap, .prop_price, .btn {
				display: none;
			}
			.property_meta_item {
				.icons {
					display: inline-block;
				}
			}
		}
		@include media-breakpoint-up(md) {
			.prop_wrap {
				display  : block;
				max-width: 40rem;
				padding  : 3rem;
				.property_info {
					> p, .property_meta_price, .block-20 {
						display: none;
					}
				}
			}
		}
		@include media-breakpoint-up(lg) {
			.prop_wrap {
				bottom   : 15rem;
				max-width: 50rem;
				padding  : 5rem;
				right    : 0;
				.prop_price, .btn {
					display: block;
				}
				.prop_meta_wrap {
					@include flexbox();
				}
				.property_info {
					> p, .property_meta_price, .block-20 {
						display: block;
					}
				}
			}
		}
	}
	/* SLIDE-ITEM-PROPERTY */

	.slider_homepage_2 {
		.item_slide {
			@include media-breakpoint-up(lg) {
				height: 75rem;
			}
		}
	}

}

/* SECTION-PROPERTIES */
#properties {
	padding: 10rem 0;
}

/* SECTION-ABOUTUS */
#about-us {
	padding: 10rem 0;
	img {
		width: 100%;
		@extend .box-shadow-base;
	}
	&.about-us-2 {
		padding: 0;
		.widget_text {
			color      : rgba(255,255,255,.8);
			margin     : 0;
			font-weight: 300;
			padding    : 5rem 1.5rem;
		}
		.widget_title {
			color         : $white;
			font          : 400 4rem $font-family-sans-serif;
			text-transform: capitalize;
		}
	}
	.bg-about {
		background         : url("../images/1920x1280.png");
		background-position: 50% 50%;
		background-size    : cover;
		background-repeat  : no-repeat;
	}
	@include media-breakpoint-up(md) {
		&.about-us-2 {
			.widget_text {
				margin : 0;
				padding: 15rem 7rem;
			}
		}
	}
}

/* SECTION-Blogs */
#blogs {
	padding: 10rem 0;
}

/* SECTION-DESTINATIONS */
#destinations {
	padding: 10rem 0;
}

/* SECTION-PARTNERS */
#partners {
	padding: 5rem 0;
}

/* SECTION-SERVICES */
#services {
	padding: 10rem 0;
}

/* SECTION-TESTIMONIALS */
#testimonials {
	padding: 10rem 0;
}


/* SECTION-AGENTS */
#agents {
	padding: 7rem 0; 
}