// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

@mixin nav-divider($color: #e5e5e5) {
  height: 0;
  margin: ($spacer / 2) 0;
  overflow: hidden;
  border-top: 1px solid $color;
}
