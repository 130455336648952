/* HEADER */
$navbar-background                : #22355b !default;
$navbar-color                     : $white !default;
$navbar-padding                   : 2.5rem 0 !default;
$navbar-text-transform            : $nocolor !default;
$navbar-font-size                 : 1.4rem !default;
$navbar-font-weight               : 700 !default;

$navbar-link-active-color         : theme-color($key: "primary") !default;
$navbar-link-active-bg            : rgba(255,255,255,.1) !default;
$navbar-link-color                : $white !default;
$navbar-link-font-family          : $font-family-serif !default;
$navbar-link-font-size            : 1.3rem !default;
$navbar-link-font-weight          : 400 !default;
$navbar-link-hover-color          : $link-hover-color !default;
$navbar-link-hover-bg             : $nocolor !default;
$navbar-link-margin               : 0 .5rem 0 0 !default;
$navbar-link-padding              : 0 1.5rem !default;
$navbar-link-transform            : uppercase !default;


$navbar-dropdown-padding          : 2rem !default;
$navbar-dropdown-bg               : $white !default;
$navbar-dropdown-size             : 25rem !default;
$navbar-dropdown-link-color       : $white !default;
$navbar-dropdown-link-hover-color : $gray-500 !default;
$navbar-dropdown-link-font-size   : 1.3rem !default;
$navbar-dropdown-link-font-weight : 700 !default;
$navbar-dropdown-link-hover-bg    : theme-color($key: "primary") !default;
$navbar-dropdown-link-padding     : .5rem 0 !default;
$navbar-dropdown-link-transform   : $nocolor !default;
$navbar-dropdown-link-border-color: $border-color !default;

