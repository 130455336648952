/* BREADCRUMBS */
#breadcrumbs {
	.breadcrumb {
		background   : $breadcrumb-bg;
		border-bottom: 1px solid #eeeeee;
		color        : $breadcrumb-color;
		margin       : $breadcrumb-margin;
		padding      : $breadcrumb-padding;
		@include border-radius(0);
		.breadcrumb-item {
			display       : inline-block;
			color         : $breadcrumb-link-color;
			font          : $breadcrumb-font-weight $breadcrumb-font-size $font-family-base;
			text-transform: $breadcrumb-text-transform;
			&.active {
				color: $breadcrumb-active-color;
			}
		}
		a {
			color: red;
			&:hover {
				color: $breadcrumb-link-hover-color;
			}
		}
	}
}

/* FOOTER */

footer {
	font-family: $font-family-serif;
	margin-top: 5rem;
	padding: 5rem 0;
	&#bf_footer {
		background: $footer-bg;
	}
	.widget {
		margin-bottom: 3rem;
		li {
			margin-bottom: .7rem;
			a {
				color: $footer-color;
				font-weight: 300;
				&:hover {
					color: $white;
				}
			}
		}

		.icons {
			color: $white;
		}

		&_title {
			color: $white;
			font-size: 1.6rem;
			letter-spacing: .1rem;
		}

		&_social {
		}

		&_links {
			li {
				a {
					color: $footer-color;
					font-family: $font-family-serif;
					font-weight: 300;
					&:hover {
						color: $white;
					}
				}
			}
		}
	}
	.footer_bottom {
		font-size: 1.4rem;
		padding-top: 3rem;
		.copyright, .designby {
			color: $footer-color;
			display: inline-block;
			font-weight: 300;
		}
	}
	@include media-breakpoint-up (lg) {
		margin-top: 13rem;
		position: relative;
		padding: 3rem 0;
		.widget {
			// margin: 0;
		}
		&:after, &:before {
			border: 0 solid transparent;
			content: "";
			position: absolute;
			top: -13rem;
		}
		&:after {
			border-bottom-width: 0;
			border-right-color: $footer-bg;
			border-right-width: 92vw;
			border-top-width: 15rem;
			right: 0;
		}
		&:before {
			border-bottom-width: 0;
			border-left-color: $footer-bg;
			border-left-width: 92vw;
			border-top-width: 15rem;
			left: 0;
		}
	}
}

/* FOOTER */

/* SIDEBAR */
.sidebar {
	.widget {
		margin-bottom: 4rem;
		&_tags {
			ul {
				li {
					margin: 0 .5rem 1rem 0;
				}
			}
		}
	}
}