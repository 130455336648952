/* About-us */
.section_about {
	.widget_text {
		@include media-breakpoint-up(lg) {
			padding-right: 5rem;
		}
	}
}

/* COMING-SOON */
.section_comingsoon {
	background-image   : url("../images/1920x1080.png");
	background-repeat  : no-repeat;
	background-position: center center;
	background-size    : cover;
	bottom             : 0;
	left               : 0;
	right              : 0;
	position           : fixed;
	top                : 0;
	.comingsoon {
		left    : 50%;
		position: absolute;
		top     : 50%;
		@include translate(-50%, -50%);

		h1 {
			font          : 400 5rem $font-family-sans-serif;
			margin-bottom : 2rem;
			text-transform: capitalize;
			@extend .text-white;
		}

		.sub {
			font-size     : 3rem;
			font-weight   : 300;
			text-transform: capitalize;
			@extend .text-white;
		}
	}
	@include media-breakpoint-up(lg) {
		.comingsoon {
			h1 {
				font-size    : 10rem;
				margin-bottom: 3rem;
			}
		}
	}
}

#countdown {
	@extend .d-flex, .justify-content-center;
	.item {
		margin: 0 1.5rem;
		span {
			display       : block;
			letter-spacing: .2rem;
			text-transform: uppercase;
			@extend .text-white;
				&.count {
				font: 400 4rem $font-family-sans-serif;
				margin-bottom: 1rem;
				text-transform: inherit;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.item {
			margin: 0 2rem;
			span {
				&.count {
					font-size: 5rem;
				}
			}
		}
	}
}

/* SINGLE-AGENT */
.detail-agent {
	.agent {
		box-shadow: none;
		&_avatar {
			margin-bottom: 1.5rem;
			&:after {
				content: none;
			}
		}
		&_info {
			border        : 1px solid #ededed;
			color         : $gray-600;
			font-weight   : 300;
			flex-direction: column;
			padding: 3rem;
			.name {
				margin-bottom: 1.5rem;
			}
			.contact_info {
				margin-bottom: 1.5rem;
				li {
					display: block;
					margin : .5rem 0;
					.icons {
						margin-right: 1rem;
					}
				}
			}
			.social {
				margin-bottom: 1.5rem;
				li {
					a {
						color: $gray-600;
						&:hover {
							color: $link-hover-color;
						}
					}
				}
			}
			.description {
				margin-bottom: 0;
			}
		}

		.sidebar {
			border: 1px solid #ededed;
			padding: 3rem;
		}
	}
	@include media-breakpoint-up(lg) {
		.agent {
			&_info {
				flex-direction: row;
			}
			&_avatar {
				margin-right: 4rem;
				max-width: 25rem;
			}
		}
	}
}

/* 404 */
.section_404 {
	background-image   : url("../images/1920x1080.png");
	background-repeat  : no-repeat;
	background-position: center center;
	background-size    : cover;
	bottom             : 0;
	left               : 0;
	right              : 0;
	position           : fixed;
	top                : 0;
	.error_page {
		left    : 50%;
		position: absolute;
		top     : 50%;
		@include translate(-50%, -50%);

		h1 {
			font          : 400 5rem $font-family-sans-serif;
			margin-bottom : 2rem;
			text-transform: capitalize;
			@extend .text-white;
		}

		.sub {
			color: $gray-200;
			font-size     : 2rem;
			font-weight   : 300;
			text-transform: capitalize;
		}

		a {
			font: 300 2rem $font-family-sans-serif;
			@extend .text-white;
		}
	}

	@include media-breakpoint-up(lg) {
		.error_page {
			h1 {
				font-size    : 10rem;
				margin-bottom: 3rem;
			}
		}
	}
}

/* SINGLE-DESTINATION */
.destination {
	&_header {
		background: url("../images/1920x1080.png");
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		height: 20rem;
		margin-bottom: 5rem;
		position: relative;
		.row > div {
			position: inherit;
		}
		h1 {
			font-size: 3rem;
			left: 50%;
			position: absolute;
			top: 50%;
			z-index: 1;
			@include translate(-50%, -50%);
		}

		&:after {
			background: rgba(101, 100, 96, 0.2);
			content: "";
			left: 0;
			position: absolute;
			top: 0;
			@include size(100%, 100%);
		}
		@include media-breakpoint-up(lg) {
			height: 30rem;
			h1 {
				font-size: 6rem;
			}
		}
	}
}