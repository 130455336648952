// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

/* TYPO */
$font-family-sans-serif: 'Playfair Display', serif;
$font-family-serif     : 'Roboto', sans-serif;
$font-family-base      : 'Rubik', sans-serif;

$font-size-base: 1.4rem; // Assumes the browser default, typically `16px`
$font-size-lg:   1.8rem;
$font-size-sm:   1.2rem;

$h1-font-size: 3.6rem;
$h2-font-size: 3rem;
$h3-font-size: 2.4rem;
$h4-font-size: 1.8rem;
$h5-font-size: 1.4rem;
$h6-font-size: 1.2rem;
/* TYPO */
$headings-color      : #222;
$headings-font-family: $font-family-serif;
$headings-font-weight: 400;

.btn {
	font-size     : 1.2rem;
	font-weight   : 700;
	letter-spacing: 2rem;
	padding       : 1rem 1.5rem;

}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	color        : $headings-color;
	font-family  : $headings-font-family;
	font-weight  : $headings-font-weight;
	line-height  : $headings-line-height;
	margin-bottom: $headings-margin-bottom;
	a {
		color: $headings-color;
	}
}

h1 {
	font-size: $h1-font-size;
}
h2 {
	font-size: $h2-font-size;
}
h3 {
	font-size: $h3-font-size;
}
h4 {
	font-size: $h4-font-size;
	margin: 0 0 1.5rem;
}
h5 {
	font-size: $h5-font-size;
}
h6 {
	font-size: $h6-font-size;
}

/* BUTTON */
.btn {
	font-family   : $font-family-serif;
	letter-spacing: .1rem;
	text-transform: uppercase;
	&:hover {
		color : $white;
		cursor: pointer;
	}
}

.btn-lg {
  @include button-size(1.5rem, 7rem, 1.4rem, 1.4rem, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size(1.3rem, 2.5rem, 1.4rem, 1.4rem, $btn-border-radius-lg);
}
/* BUTTON */

/* ALERT */
.alert {
	padding: 2rem;
}
/* ALERT */

/* TRANSITION */
$transition-base: all .3s ease-in-out;
/* TRANSITION */

/* FORM */
label {
	color: $gray-700;
}

textarea {
	min-height: 15rem;
}