.bf_header {
	background: $navbar-background;
	z-index   : $zindex-fixed;
	&.header-absolute {
		background: $nocolor;
		position: absolute;
		left    : 0;
		right   : 0;
		top     : 0;
	}
	a, span {
		display    : inline-block;
		line-height: 3rem;
		padding    : 0 1rem;
		@include border-radius($border-radius-lg);
		&:hover {
			background: rgba(255,255,255,.1);
			cursor: pointer;
		}
	}
	.dropdown-menu {
		background: $navbar-background;
		border    : none;
		display   : block;
		margin    : 1rem 0 0 0;
		opacity   : 0;
		padding   : $navbar-dropdown-padding;
		top       : 120%;
		visibility: hidden;
		@extend .box-shadow-base;
		@include border-radius($border-radius-lg);
		@include transition($transition-base);
		> li {
			> a {
				background: transparent;
				color      : $navbar-dropdown-link-color;
				font       : 400 $navbar-dropdown-link-font-size $font-family-serif;
				line-height: 1.5;
				padding    : $navbar-dropdown-link-padding;
				&:hover {
					color: $navbar-dropdown-link-hover-color;
				}
			}
		}
		li {
			a {
				white-space: nowrap;
			}
		}
	}
	
	/* DROPDOWN-MENU */
	.dropdown {
		&.dropdown_mega {
			position: inherit;
		}

		&:hover {
			.dropdown-menu {
				opacity   : 1;
				top       : 100%;
				visibility: visible;
			}
		}
	}
}

.bf_header {
	padding: 0;

	/* TOPBAR */
	.header_topbar {
		border-bottom: $topbar-border;
		color        : $topbar-color;
		font         : 400 $topbar-font-size $font-family-serif;
		padding      : $topbar-padding; 
		.icons {
			font-size   : $topbar-icon-font-size;
			line-height : $topbar-icon-font-size;
			margin-right: .5rem;
		}
		.topbar_contact {
			line-height: 3rem;
			margin     : 0;
			> div {
				display     : inline-block;
				margin-right: 1rem;
				&:last-child {
					margin: 0;
				}
			}
		}

		.topbar_right {
			.topbar_user {
				> a {
					color  : $white;
					display: inline-block;
					margin : $topbar-text-margin;
				}
			}
			.dropdown {
				&.lang {
					margin: 0;
				}
			}
		}

		.topbar_right, .topbar_left {
			font-size: 1rem;
		}

		@include media-breakpoint-up(md) {
			.topbar_contact {
				> div {
					margin-right: 1.5rem;
				}
			}
			.topbar_right, .topbar_left {
				font-size: 1.2rem;
			}
		}
	}
	
	/* MENU */
	.header_menu {
		padding: $navbar-padding;
		@include transition(all .2s);
		.header_brand {
			.logo {
				max-width: 15rem;
				img {
					width: 100%;
				}
				&:hover {
					background: transparent;
				}
			}
		}
		.navbar {
			display: none !important;
			@include media-breakpoint-up(lg) {
				display: flex !important;
			}
		}
		.hamburger {
			background: $nocolor;
			border    : none;
			display   : none;
			float     : right;
			padding   : 0;
			width     : 3rem;
			&:hover {
				cursor: pointer;
			}
			.line {
				background: $white;
				display   : block;
				margin    : .5rem 0;
				padding   : 0;
				@include border-radius(0);
				@include size(3rem, .3rem);
				@include transition($transition-base);
			}
         &:after {
				display: none;
			}
			@include media-breakpoint-down(md) {
				display: block;
			}
		}
	}
	
	/* NAVBAR */
	.navbar {
		padding: 0;
		.navbar-nav {
			> li {
				> a {
					color         : $navbar-link-color;
					font          : $navbar-link-font-weight $navbar-link-font-size $navbar-link-font-family;
					line-height   : 3rem;
					margin        : $navbar-link-margin;
					padding       : $navbar-link-padding;
					text-transform: $navbar-link-transform;
				}
				&.active {
					> a {
						background: $navbar-link-active-bg;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			display   : block;
			text-align: right;
		}
	}

	/* sticky-menu */
	&.menu_sticky {
		background: rgba(0,0,0,.8);
		position  : fixed;
		width: 100%;
		@include transition($transition-base);
		.header_topbar {
			display: none;
		}
		.header_menu {
			padding: 1.5rem 0;
		}
		.dropdown-menu {
			background: $white;
			li {
				a {
					color: $gray-700;
					&:hover {
						color: $link-hover-color;
					}
				}
			}
			.dropdown_menu_heading {
				color: $gray-700;
			}
		}
	}
}



/* MEGA-MENU */
.dropdown {
	&_mega {
		.dropdown-menu {
			padding: 2rem 3rem;
			width  : 100%;
		}
	}
	&_mega_menu {
		ul {
			list-style: none;
			padding: 0;
			li {
				a {
					color      : $white;
					font       : 400 $navbar-dropdown-link-font-size $font-family-serif;
					line-height: 1.5;
					padding    : $navbar-dropdown-link-padding;
					&:hover {
						background: transparent;
						color: $gray-500;
					}
				}
			}
		}
	}
	.dropdown_menu_heading {
		color  : $gray-700;
		display: inline-block;
		font   : 500 1.4rem $font-family-base;
		margin : 0 0 1rem;
		padding: 0;
		text-transform: uppercase;
		&:hover {
			background: transparent;
		}
	}
	@include media-breakpoint-up(lg) {
		.dropdown_menu_heading {
			color: $white;
		}
	}
}

/* MENU-OFF-CANVAS */

.menu-off-canvas {
	#navbar-offcanvas {
		overflow-y: auto;
	}
	> .dropdown-menu {
		background: $white;
		border    : none;
		display   : block !important;
		float     : inherit;
		left      : inherit;
		margin    : 0;
		opacity   : 0;
		padding   : 0;
		position  : fixed;
		top       : 0;
		right     : 0;
		visibility: hidden;
		z-index   : $zindex-modal;
		@include border-radius(0);
		@include size(25rem, 100%);
		@include transition($transition-base);
		@include translateX(100%);
		&.show {
			opacity: 1;
			right: 0;
			visibility: visible;
		}
		.menu-canvas-header {
			background  : theme-color($key: "primary");
			color       : $white;
			padding-left: 2rem;
			h3 {
				color         : $white;
				font          : 400 1.4rem $font-family-base;
				letter-spacing: .2rem;
				line-height   : 5rem;
				margin        : 0;
				text-transform: uppercase;
			}
			.close {
				background : darken(theme-color($key:"primary"), 5%);
				color      : $white;
				font-size  : 1.6rem;
				font-family: 'Rubik', sans-serif;
				opacity    : 1;
				position   : absolute;
				text-shadow: none;
				top        : 0;
				right      : 0;
				@include border-radius(0);
				@include size(5rem, 5rem);
			}
		}
	}
	.navbar-nav {
		padding: 3rem 2rem;
		li {
			a {
				color : $gray-700;
				font  : 400 1.2rem $font-family-base;
				margin: .5rem 0;
				&:hover {
					color: $link-hover-color;
				}
			}
			.dropdown-menu {
				border      : none;
				margin      : 0;
				padding-left: 1.5rem;
				position    : inherit;
				top         : inherit;
				a {
					display       : inline-block;
					margin        : 0;
					padding       : .7rem 0;
					text-transform: capitalize;
				}
			}
			&.show {
				> .dropdown-menu {
					display: block;
				}
			}

		}
		> li {
			> a {
				font-size     : 1.4rem;
				text-transform: capitalize;
				&:after {
					float     : right;
					margin-top: .5rem;
				}
			}
		}
	}
}

.menu-off-canvas-open {
	.navbar-offcanvas {
		right     : 0;
		opacity   : 1;
		visibility: visible;
		@include translateX(0);
	}
}

/* MENU-OFF-CANVAS */

/* PROPERTY-SELECTION */
.property_selection {
	background   : $white;
	line-height  : 3.5rem;
	margin-bottom: 3rem;
	padding      : 1.5rem 2rem; 
	&_wrap {
		> div {
			text-align: center;
			@extend .col-6;
		}
	}
	.display {
		> span {
			display: inline-block;
			@include size(2rem, 2rem);
			&:hover {
				cursor: pointer;
			}
			&.grid {
				margin-right: 1rem;
				opacity: .5;
			}
		}
	}
	.filter {
		select {
			height        : 3.5rem !important;
			text-align    : center;
			text-transform: capitalize;
			width         : 100%;
			@include x-appearance(none);
			option {
				font   : 300 1.4rem $font-family-base;
				padding: .5rem 0;
			}
		}
		.wrap {
			position: relative;
			&:after {
				content : "\f0d7";
				font    : 400 1.2rem Fontawesome;
				position: absolute;
				right   : 1rem;
				top     : 50%;
				@include translateY(-50%);
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.filter {
			select {
				min-width: 18rem;
				width: 18rem;
			}
			.wrap {
				&:after {
					right   : 2rem;
				}
			}
		}
	}
}

.property_list {
	.display {
		.list {
			opacity: .5;
		}
		.grid {
			opacity: 1 !important;
		}
	}
}