/* PROPERTY */
.property {
	margin-bottom: 4rem;
	@include transition($transition-base);
	&_thumbnail {
		img {
			width: 100%;
		}
	}
	&_info {
		color: $gray-700;
		font-weight: 300;
		&_title {
			margin-bottom: 1.5rem;
			a {
				font: 400 3rem $font-family-sans-serif;
			}
		}
		h1.property_info_title {
			font       : 400 3.6rem $font-family-sans-serif;
			line-height: 3.6rem;
			margin-bottom: 2rem;
		}
		&_meta {
			.property_meta_item {
				margin-right: 1.5rem;
				text-align  : center;
				.icons {
					margin: 0 0 1rem;
					width : 2.5rem;
				}
				span {
					display  : block;
					font-size: 1.2rem;
				}
				&:last-child {
					margin: 0;
				}
			}
		}
	}

	&_meta_rate {
		margin-bottom : 1.5rem;
		text-transform: uppercase;
		p {
			display: inline-block;
			margin : 0 .5rem 0 0;
		}
		img {
			margin-bottom: .5rem;
			width        : 1.5rem;
		}
	}

	&_meta_price {
		color: theme-color($key: "primary");
		font : 500 3rem $font-family-serif;
		.sub {
			color    : $gray-700;
			font-size: 1.4rem;
		}
	}

	&_catalog {
		border: 1px solid #ededed;
		@include transition($transition-base);
		.property_info {
			background: $white;
			padding: 3rem;
		}
		&:hover {
			@extend .box-shadow-base;
		}
	}

	&_overlay {
		position: relative;
		.property_thumbnail {
			&:after {
				background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
				bottom    : 0;
				content   : "";
				left      : 0;
				position  : absolute;
				width     : 100%;
				height    : 100%;
			}
		}
		.property_info {
			background: transparent;
			bottom: 0;
			left: 0;
			padding: 3rem;
			position: absolute;
			width: 100%;
			&_title {
				margin-bottom: 1.5rem;
				a {
					color: $white;
				}
			}
			.property_meta_rate, .property_meta_price {
				color: $white;
				margin: 0;
			}
			.property_meta_price {
				.sub {
					color: $white;
				}
			}
		}
	}
}

.property_list {

	.property_wrapper {
		@extend .col-md-12;
	}
	@include media-breakpoint-up(lg) {
		.property_catalog {
			@include flexbox();
			.property_thumbnail {
				overflow: hidden;
				position: relative;
				width: 40rem;
				img {
					height: 100%;
					left: 50%;
					position: absolute;
					width: inherit;
					@include translateX(-50%);
				}
			}
		}
	}

}

.property-single {
	ul {
		list-style-type: none;
		margin         : 0;
		padding        : 0;
	}
	.property {
		&_header {

		}
		&_info {
			margin-bottom: 2rem;
		}
		&_meta {
			&_location {
				margin-bottom: 1.5rem;
				.icons {
					font-size   : 1.3rem;
					margin-right: .5rem;
				}
			}
			&_price {
				margin: 2rem 0;
			}
		}
		&_content {
			.property_section {
				color        : $gray-700;
				line-height  : 2.5rem;
				margin-bottom: 3rem;
				padding      : 3rem 1.5rem;
				h3 {
					font         : 500 1.6rem $font-family-base;
					margin-bottom: 2rem;
				}
			}
			&_detail {
				ul {
					li {
						font-weight: 300;
						margin     : .5rem 0;
						span {
							display: block;
							font-weight : 500;
							margin-right: .5rem;
						}
					}
				}
			}
			&_service {
				.service {
					padding: 1.5rem 0;
					@extend .flex-column;
					> div {
						margin-bottom: 0;
						h3 {
							margin: 0;
							font-weight: 400;
						}
					}
					.icons {
						margin: 0 1rem 1.5rem 0;
						img {
							width: 3rem;
						}
					}
				}
			}
		}
	}
	
	#property_video {
		iframe {
			height: 25rem;
		}
	}
	.sidebar {
		.widget {
			padding: 3rem 1.5rem;
			&_agents {
				.agent {
					box-shadow   : none;
					margin-bottom: 3rem;
					&.agent_small {
						p {
							font-size: 1.2rem;
						}
					}
					&_avatar {
						margin-right: 1.5rem;
						width       : 10rem;
						&:after {
							content: none;
						}
					}
					&_info {
						padding: 0;
					}
					&:last-child {
						margin: 0;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		#property_video {
			iframe {
				height: 63rem;
			}
		}
		.property {

			&_info {
				align-items    : center;
				justify-content: space-between;
				@include flexbox();
			}

			&_meta {
				&_item {
					margin-right: 3rem;
					.icons {
						width: 4rem;
					}
					span {
						font-size: 1.4rem;
					}
				}
				&_price {
					font-size  : 4rem;
					font-weight: 700;
				}
			}

			&_content {
				.property_section {
					padding: 3rem;
					span {
						display: inline-block;
					}
				}
				&_service {
					.service {
						text-align: center;
						.icons {
							margin: 0 0 1.5rem;
						}
					}
				}
			}

		}
		.sidebar {
			.widget {
				padding: 3rem;
			}
		}
	}
}

/* PROPERTY */