html {
	font-size: 62.5%;
}

body {
	font-size: 1.4rem;
	font-family: $font-family-base;
	line-height: 1.6;
	overflow-x: hidden;
}

.bg-gray {
	background: #f9f9f9;
}
.bg-dark {
	background: #1c1c1c !important;
}

.modal-backdrop {
	z-index: $zindex-sticky;
}

a {
	&:hover {
		text-decoration: none;
	}
}

video, audio {
	display: block;
}

.d_flex {
	@include flexbox();
}

.d_flex_between {
	justify-content: space-between;
}

.d_flex_justify_end {
	justify-content: flex-end;
}

// Form
.form-control {
	font: 300 1.4rem $font-family-base;
	color: $gray-700;
	height: 4.5rem;
	padding: .5rem 1.5rem;
	@include border-radius($border-radius-lg);
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: $gray-700;
	}
	&::-moz-placeholder { /* Firefox 19+ */
	  color: $gray-700;
	}
	&:-ms-input-placeholder { /* IE 10+ */
	  color: $gray-700;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: $gray-700;
	}
}

select.form-control {
	&:not([size]):not([multiple]) {
		color: $gray-700;
		font: 500 1.2rem $font-family-base;
		height: 4.5rem;
	}
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: $gray-700;
	}
	&::-moz-placeholder { /* Firefox 19+ */
	  color: $gray-700;
	}
	&:-ms-input-placeholder { /* IE 10+ */
	  color: $gray-700;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: $gray-700;
	}
}
// Form

/* WRAPPER CLICK DISBALE MENU */
.click-wrapper {
	background: rgba(0,0,0,.5);
	left: 0;
	position: fixed;
	top: 0;
	visibility: hidden;
	z-index: 1035;
	@include size(100%, 100%);
	@include transition($transition-base);
}

.menu-off-canvas-open {
	.click-wrapper {
		visibility: visible;
	}
}
/* WRAPPER CLICK DISBALE MENU */

/* JUMBOTRON */
.jumbotron {
	background: transparent;
	margin: 0;
	overflow: hidden;
}
/* JUMBOTRON */

/* SPACE */
.padding-1 {
	padding: 1rem 0;
}
.padding-2 {
	padding: 2rem 0;
}
.padding-3 {
	padding: 3rem 0;
}
.padding-4 {
	padding: 4rem 0;
}
.padding-5 {
	padding: 5rem 0;
}

.block-10 {
	height: 1rem;
}
.block-20 {
	height: 2rem;
}
.block-30 {
	height: 3rem;
}
.block-40 {
	height: 4rem;
}
.block-50 {
	height: 5rem;
}
.block-60 {
	height: 6rem;
}
.block-70 {
	height: 7rem;
}
.block-80 {
	height: 8rem;
}
.block-90 {
	height: 9rem;
}
.block-100 {
	height: 10rem;
}
/* SPACE */

/* COLUMN */
.column_1 {
	@include columns(1);
}
.column_2 {
	@include columns(2);
}
.column_3 {
	@include columns(3);
}
.column_4 {
	@include columns(4);
}
.column {
	margin  : 0 0 1.5rem;
	padding : 0;
	overflow: hidden;
	@include media-breakpoint-up(lg) {
		margin : 0;
		padding: 0 1.5rem;
	}
}

/* BOX-SHADOW-BASE */
.box-shadow-base {
	box-shadow        : 0 1rem 2.5rem rgba(0, 0, 0, 0.1);
	-ms--box-shadow   : 0 1rem 2.5rem rgba(0, 0, 0, 0.1);
	-moz-box-shadow   : 0 1rem 2.5rem rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.1);
	-o-box-shadow     : 0 1rem 2.5rem rgba(0, 0, 0, 0.1);
}

/* LINE-HR */
.line-hr {
	background-image: -webkit-linear-gradient(left, transparent, #DBDBDB, transparent);
	background-image: linear-gradient(to right, transparent, #DBDBDB, transparent);
	border          : none;
	height          : .1rem;
	margin          : 5rem 0;
}

.hr {
	&_left {
		margin-left: 0;
	}
	&_10 {
		@include size(1rem, .1rem);
	}
	&_20 {
		@include size(2rem, .1rem);
	}
	&_30 {
		@include size(3rem, .1rem);
	}
	&_40 {
		@include size(4rem, .1rem);
	}
	&_50 {
		@include size(5rem, .1rem);
	}

	&_white {
		border-color: $white;
	}
	&_dark {
		border-color: $headings-color;
	}
}

.map-icon {
	display: block !important;
	width: 5rem;
}

.grid {
	&:after {
		clear  : both;
		content: '';
		display: block;
	}
	&-item {
		float: left;
	}
}