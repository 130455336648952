/**
	Template Name: Butterfly
	Template URI: http://butterfly.firtheme.com/
	Author: Engo Team
	Author URI: http://butterfly.firtheme.com/
	Description: Butterfly - Responsive Real Estate HTML Template
	Version: 1.0
	Last change:	2017/10/26 [Initial Release]
	License: GNU General Public License v2 or later
	Tags: four-columns, left-sidebar, right-sidebar, flexible-layout, responsive-layout, retina, estate, firtheme.com, firtheme
	Text Domain: firtheme
 **/

/*------------------------------------------------------------------
[Table of contents]

	1. Import Bootstrap

	2. Import Component

		2.1. Mixins

		2.2. Variables
			2.2.1.	Form
			2.2.2.	Layout
			2.2.3.	Navigation
			2.2.4.	Widget

		2.3. Default base
			2.3.1 	Body
			2.3.2 	Spacing
			2.3.3 	Columns
			2.3.4 	Layout
			...

		2.4. Element
			2.4.1		Form check available
			2.4.2		Widget
			2.4.3		Shortcode Accordions
			2.4.4 	Shortcode Carousel
			2.4.5		Shortcode Services
			2.4.6		Shortcode Lightboxes
			2.4.7  	Shortcode Teams
			2.4.8 	Shortcode Agents
			2.4.9  	Shortcode Testimonial
			2.4.10	Shortcode Gallery
			2.4.11 	Shortcode Paginations
			2.4.12 	Shortcode Comments
			2.4.13 	Shortcode Destinations
			2.4.14  	Shortcode Form login-register
			2.4.15 	Shortcode Preloader

		2.5. Form

		2.6. Layout
			2.6.1		Footer
			2.6.2 	Breadcrumbs
			2.6.3		Sidebar
		
		2.7. Nav
			2.7.1		Mega Menu
			2.7.2		Menu Off Canvas

		2.8. Page
			2.8.1 	About-us
			2.8.2		Comming Soon
			2.8.3		Single Agent
			2.8.4		404
			2.8.5		Single Destinations

		2.9. Post
			2.9.1 	Blog Catalog
			2.9.2 	Blog Single

		2.10. Room Property
			2.10.1 	Property Catalog
			2.10.2 	Property Single

		2.11. Sections 
			2.11.1 	Jumbotron
			2.11.2 	Properties
			2.11.3 	About-us
			2.11.4 	Blogs
			2.11.5 	Destinations
			2.11.6 	Partners
			2.11.7 	Services
			2.11.8 	Testimonial
			2.11.9 	Agents

		2.12. Typo Heading

-------------------------------------------------------------------*/

/* Import Bootstrap */
@import	'bootstrap/bootstrap';

/* Import Component variables */
@import	'component/variables';

/* Import Component mixins */
@import	'component/mixins';

/* Import Component typo */
@import	'component/typo';

/* Import Component base */
@import	'component/base';

/* Import Component element */
@import	'component/element';

/* Import Component form */
@import	'component/form';

/* Import Component navigation */
@import	'component/nav';

/* Import Component layout */
@import	'component/layout';

/* Import Component page orther */
@import	'component/page';

/* Import Component post */
@import	'component/post';

/* Import Component room property */
@import	'component/room';

/* Import Component sections */
@import	'component/sections';