/* FORM-CHECK-AVAILABILITY */
.bf_check_available_form {
	position: relative;
	@include media-breakpoint-up(lg) {
		margin-top: -4.5rem;
		z-index: $zindex-sticky;
	}
}
.bf_check_availability {
	margin: 0;
	padding: 2rem 0;
	.bf_calendar {
		&_wrapper {
			position: relative;
			.icon {
				color: $gray-700;
				font-size: 1.2rem;
				right: 2rem;
				position: absolute;
				top: 50%;
				@include translateY(-50%);
			}
		}
	}
	.form-group {
		margin: 0 0 1.5rem;
		label {
			font: 300 1.2rem $font-family-serif;
			line-height: 1.2rem;
			margin-bottom: .7rem;
			text-transform: uppercase;
		}
		.form-select-wrap {
			position: relative;
			&:after {
				content: "\f0d7";
				font: 400 1.2rem Fontawesome;
				position: absolute;
				right: 1.5rem;
				top: 50%;
				@include translateY(-50%);
			}
		}
	}

	select {
		@include x-appearance(none);
		option {
			color: $gray-900;
			font: 400 1.2rem $font-family-base;
			padding: .3rem 1rem;
			&:hover {
				background: theme-color($key: "primary");
				color: $white;
			}
		}
	}

	.bf_search_wrap {
		@include flexbox();
		.btn {
			color: $white;
			font: 500 1.2rem $font-family-serif;
			height: 4.5rem;
			padding: 1.5rem 3rem;
			&:hover {
				color: $white;
				cursor: pointer;
			}
		}
		.bf_searchbtn {
			margin-right: 1rem;
			.icons {
				display: none;
			}
		}
		.bf_search_adv {
			.btn {
				font-size: 1.4rem;
				line-height: 4.5rem;
				padding: 0;
				@include size(4.5rem, 4.5rem);
				.icons {
					display: inline-block;
					@include transition($transition-base);
				}
			}
		}
		@include media-breakpoint-between(md, lg) {
			.bf_searchbtn {
				span {
					display: none;
				}
				.icons {
					display: block;
				}
			}
		}
	}
	
	.advence_fields {
		height: 0;
		opacity: 0;
		overflow: hidden;
		@include transition($transition-base);
		.row {
			> div {
				opacity: 0;
				visibility: hidden;
				@include transition($transition-base);
			}
		}
	}
	&.form-adv-active {
		.advence_fields {
			height: auto;
			margin-top: 2rem;
			opacity: 1;
			.row {
				> div {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		.bf_search_adv {
			.btn {
				.icons {
					@include rotate(135);
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		background: #22355b;
		padding: 1.5rem 2rem;
		.row {
			margin: 0 -1rem;
		}
		.form-group {
			margin: 0;
			padding: 0 1rem;
			label {
				color: $white;
			}
			select {
				border: none;
			}
		}
		.bf_search_wrap {
			margin-top: 2.4rem;
		}
	}
}
/* FORM-CHECK-AVAILABILITY */

/* DATE-PICKER */
.ui-datepicker {
	box-shadow: 0 .5rem 1.5rem rgba(0,0,0,0.3);
	width: 30rem;
	.ui-datepicker-header {
		background: theme-color($key: "primary");
		position: relative;
		.ui-corner-all {
			line-height: 5rem;
			position: absolute;
			text-align: center;
			top: 50%;
			@include size(5rem, 100%);
			@include translateY(-50%);
			&:hover {
				background: lighten(theme-color($key: "primary"), 5%);
				cursor: pointer;
			}
			&:before {
				color: $white;
				font: 700 1.4rem 'FontAwesome';
			}
			&.ui-datepicker-prev {
				left: 0;
				&:before {
					content: "\f060";
				}
			}
			&.ui-datepicker-next {
				right: 0;
				&:before {
					content: "\f061";
				}
			}
			> span {
				display: none;
			}
		}
		.ui-datepicker-title {
			line-height: 5rem;
			text-align: center;
			> span {
				color: $white;
				font: 700 1.4rem $font-family-base;
				text-transform: uppercase;
			}
		}
	}
	table {
		background: $white;
		width: 100%;
		thead {
			background: lighten(theme-color($key: "primary"), 10%);
			th {
				text-align: center;
				> span {
					color: $white;
					display: inline-block;
					font: 500 1.4rem $font-size-base;
					padding: 1rem .5rem;
				}
			}
		}
		tbody {
			border-bottom: .8rem solid $white;
			border-top: .5rem solid $white;
			td {
				text-align: center;
				a {
					display: inline-block;
					border: .1rem solid $border-color;
					color: $gray-700;
					font: 600 1.2rem $font-family-base;
					line-height: 2.2;
					margin: .5rem 0 0 0;
					@include size(3rem, 3rem);
					&:hover {
						border-color: theme-color($key: "primary");
					}
					&.ui-state-active {
						background: theme-color($key: "primary");
						border-color: theme-color($key: "primary");
						color: $white;
					}
				}
			}
		}
	}
}
/* DATE-PICKER */


/* WIDGET */
.widget {
	margin-bottom: 3rem;
	.title {
		font          : 500 1.8rem $font-family-base;
		margin-bottom : 2.5rem;
		text-transform: capitalize;
	}
	a {
		font-size: 1.4rem;
		font-family: $font-family-serif;
	}

	ul {
		list-style: none;
		margin    : 0;
		padding   : 0;
	}
	.icons {
		font-size   : 1.6rem;
		margin-right: .5rem;
		min-width   : 1.5rem;
		text-align  : center;
	}

	/* WIDGET-NEWSLETTER */
	&_newsletter {
		.form-text {
			font  : 300 1.4rem $font-family-serif;
			margin: 0 0 1.5rem;
		}
		.form-control {
			border-color: transparent;
		}
	}

	/* WIDGET-SOCIAL */
	&_social {
		text-align: center;
		li {
			display: inline-block;
			margin : 0 .5rem;
			a {
				color    : $gray-700;
				display  : inline-block;
				font-size: 1.8rem;
				&:hover {
					color: $link-hover-color;
				}
			}
		}
	}

	/* WIDGET-INFOMATION */
	&_infomation {
		ul {
			li {
				margin: 1rem 0;
				.icons {
					font-size: 1.6rem;
					margin-right: 1rem;
				}
			}
		}
	}
	
	/* WIDGET-TITLE */
	&_title {
		font          : 500 2.6rem $font-family-serif;
		margin        : 0 0 1.5rem;
		position      : relative;
		text-transform: uppercase;
		&.style_2 {
			font-weight   : 400;
			margin-bottom : 3rem;
			padding-bottom: 2rem;
			text-transform: capitalize;
			&:after {
				background: theme-color($key: "primary");
				bottom    : 0;
				content   : "";
				left      : 0;
				position  : absolute;
				@include size(5rem, .2rem);
			}
		}
	}
	
	/* WIDGET-LINKS */
	&_links {
		li {
			a {
				color: $gray-800;
				font : 400 1.4rem $font-family-base;
				&:hover {
					color: $link-hover-color;
				}
			}
		}
	}

	/* WIDGET-VIDEO */
	&_video {
		position: relative;
		.img_wrap {
			img {
				width: 100%;
			}
		}
		.video_wrap {
			.popup_video {
				left    : 50%;
				position: absolute;
				top     : 50%;
				@include translate(-50%, -50%);
				.icons {
					color    : theme-color($key:"primary");
					font-size: 6.5rem;
					@include transition($transition-base);
				}
				&:hover {
					.icons {
						color: $white;
					}
				}
			}
		}
	}
	
	/* WIDGET-TAGS */
	&_tags {
		text-transform: uppercase;
		ul {
			display   : inline-block;
			list-style: none;
			margin    : 0;
			padding   : 0;
			li {
				display: inline-block;
				margin : 0 .5rem 1rem 0;
				a {
					border        : 1px solid $gray-300;
					color         : $gray-600;
					font          : 300 1.2rem $font-family-base;
					padding       : .5rem 1rem;
					text-transform: capitalize;
					&:hover {
						border-color: theme-color($key: "primary");
						color       : theme-color($key: "primary");
					}
				}
			}
		}
	}

	/* WIDGET-CATEGORIES */
	&_categories {
		ul {
			li {
				margin: .5rem 0;
				a {
					color: $gray-900;
					font-weight: 400;
					&:hover {
						color: theme-color($key: "primary");
					}
				}
			}
		}
	}

	/* WIDGET-BLOG */
	&_blog {
		.list-recent-post {
			.media {
				&-thumbnail {
					margin-right: 1.5rem;
					width: 10rem;
					img {
						width: 100%;
					}
				}
				&-body {
					h5 {
						a {
							color: $headings-color;
							font: 500 1.4rem $font-family-base;
							&:hover {
								color: $link-hover-color;
							}
						}
					}
				}
				&-meta {
					* {
						font-size: 1.2rem;
					}
					.date {
						margin-right: 1rem;
					}
					.price {
						color: theme-color($key: "primary");
						font: 500 1.4rem $font-family-base;
					}
				}
			}
		}
	}

	/* WIDGET-FORM-CHECKAVAILABILITY */
	&_availability_form {
		padding   : 3rem;
		background: #22355b;
		.bf_check_availability {
			padding: 0;
			.form-group {
				margin-bottom: 2rem;
				padding: 0;
				&:last-child {
					margin: 0;
				}
			}
		}
		@include media-breakpoint-up(lg) {

		}
	}
}
ul {
	&.widget {
		list-style: none;
		margin    : 0;
		padding   : 0;
	}
}
/* WIDGET */

/* SHORCODES - ACCORDIONS */
.accordion-group {
	margin-bottom: 2rem;
	.card {
		margin: 0 0 .3rem;
		&-header {
			padding: 1.5rem;
			a {
				color: $gray-900;
				&:hover {
					color: $link-hover-color;
				}
			}
			&.text-white {
				a {
					color: $white;
				}
			}
		}
		&-body {
			color: $gray-700;
		}
	}
}

#accordion-small {
	.card {
		&-header {
			padding: 1rem;
			a {
				font-size: 1rem;
			}
		}
		&-body {
			font-size: 1.2rem;
		}
	}
}
#accordion-large {
	.card {
		&-header {
			padding: 1.6rem;
			a {
				font-size: 1.8rem;
			}
		}
		&-body {
			font-size: 1.6rem;
		}
	}
}

/* OWL-CAROUSEL */
.owl-carousel {
	.owl-nav {
		[class*="owl-"] {
			background: lighten(theme-color($key:"primary"), 20%);
			font-size: 0;
			position: absolute;
			top: 50%;
			@include size(3rem, 3rem);
			@include translateY(-50%);
			&:hover {
				background: theme-color($key: "primary");
			}
		}
		> div {
			&:after {
				font-family: FontAwesome;
				font-size: 1.5rem;
				position: relative;
			}
			&.owl-prev {
				left: 0;
				&:after {
					content: "\f104";
					left: -.1rem;
					top: -.2rem;
				}
			}
			&.owl-next {
				right: 0;
				&:after {
					content: "\f105"; 
					left: .1rem;
					top: -.1rem;
				}
			}
		}
	}
	.owl-dots {
		.owl-dot {
			&:hover {
				span {
					background: theme-color($key: "primary");
				}
			}
			&.active {
				span {
					background: theme-color($key: "primary");
				}
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.owl-nav {
			[class*="owl-"] {
				opacity: 0;
				visibility: hidden;
				@include transition($transition-base);
			}
		}
		&:hover {
			.owl-nav {
				[class*="owl-"] {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

/* SHORTCODE - SERVICES */
.service {
	padding: 3rem 0;
	z-index: 9;
	&_title {
		font-family: $font-family-sans-serif;
	}
	p {
		color: $gray-700;
		margin-bottom: 3rem;
	}
	&_content {
		> * {
			margin-bottom: 1.5rem;
		}
	}
	> * {
		margin-bottom: 1.5rem;
	}

	&.text-white {
		.service_title, p {
			color: $white;
		}
	}

	&_icon_left {
		.icons {
			margin-right: 2rem;
		}
	}
	&_bg {
		background         : url("../images/1920x820.png");
		background-position: center center;
		background-repeat  : no-repeat;
		background-size    : cover;
		position           : relative;
		padding            : 10rem 0;
	}
}

/* SHORTCODES - LIGHTBOXES */
.img-wrap {
	display : block;
	position: relative;
	img {
		width: 100%;
	}
	.icons {
		color     : theme-color($key: "primary");
		font-size : 3rem;
		left      : 50%;
		opacity   : 0;
		position  : absolute;
		visibility: hidden;
		top       : 50%;
		@include translate(-50%, -50%);
	}
   &:hover {
		.icons {
			opacity   : 1;
			visibility: visible;
		}
	}
	&.img-border {
		border : 1px solid $gray-500;
		padding: .5rem;
	}
}

.white-popup {
	background: #FFF;
	padding   : 2rem;
	position  : relative;
	margin    : 2rem auto;
	max-width : 50rem;
	width     :auto;
}

/* SHORTCODES - Teams */
.team {
	margin: 0 0 3rem;
	@extend .box-shadow-base;

	* {
		@include transition($transition-base);
	}

	&_avatar {
		overflow: hidden;
		position: relative;
		img {
			width: 100%;
		}
		.social {
			bottom: 0;
			left: 0;
			padding: 1.5rem;
			position: absolute;
			text-align: center;
			width: 100%;
		}
	}

	&_info {
		padding: 2rem 1.5rem;
		.name {
			margin-bottom: 1rem;
			a {
				color: $gray-700;
				font: 500 1.6rem $font-family-base;
			}
		}
		.description {
			color: $gray-600;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			margin: 0 .5rem;
			a {
				color: $gray-700;
				font-size: 1.6rem;
			}
		}
	}

	a {
		&:hover {
			color: $link-hover-color;
		}
	}

	&.team_animate {
		&:hover {
			.team_avatar {
				img {
					@include scale(1.2);
				}
			}
		}
	}
	@include media-breakpoint-up(xl) {
		margin: 0;
		&.team_extra {
			.team {
				&_avatar {
					.social {
						opacity: 0;
						visibility: hidden;
					}
				}
			}
			&:hover {
				.social {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

/* SHORTCODES - AGENTS */
.agent {
	margin: 0 0 4rem;
	@extend .box-shadow-base;

	* {
		@include transition($transition-base);
	}

	&_avatar {
		overflow: hidden;
		position: relative;
		img {
			width: 100%;
		}

		&:after {
			background: rgba(0,0,0,.5);
			content   : "";
			left      : 0;
			opacity   : 0;
			position  : absolute;
			top       : 0;
			visibility: hidden;
			@include size(100%, 100%);
			@include transition($transition-base);
		}
		
		.btn {
			border-color: $white;
			color       : $white;
			left        : 50%;
			opacity     : 0;
			position    : absolute;
			top         : 50%;
			visibility  : hidden;
			z-index     : 9;
			@include translate(-50%, -50%);
         &:hover {
				color: $white;
				border-color: theme-color($key: "primary");
			}
		}
	}

	&_info {
		padding: 2rem 1.5rem;
		.name {
			margin-bottom: 2rem;
			a {
				color: $gray-700;
				font: 500 1.6rem $font-family-base;
			}
		}
		p {
			margin-bottom: 2rem;
		}
		.description {
			color: $gray-600;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			margin: 0 .5rem;
			a {
				color: $gray-700;
				font-size: 1.6rem;
			}
		}
	}

	a {
		&:hover {
			color: $link-hover-color;
		}
	}

	&:hover {
		.agent_avatar {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
		.btn {
			opacity: 1;
			visibility: visible;
		}
	}

	@include media-breakpoint-up(xl) {
		margin: 0;
	}
}

/* SHORTCODES - TESTIMONIALS */
.testimonial {
	blockquote {
		font         : 400 1.4rem $font-family-base;
		line-height  : 2.2rem;
		margin-bottom: 1.5rem;
		padding      : 1.5rem 2rem;
		position     : relative;
		@include border-radius($border-radius-lg);
		&:before {
			color      : $gray-500;
			content    : "\f10d";
			font-family: Fontawesome;
			left       : 1.5rem;
			position   : absolute;
			top        : 1rem;
		}
		&.quote_bg {
			margin-bottom: 3rem;
			padding      : 3rem;
			&:before {
				content: none;
			}
			&:after {
				border-left : 1rem solid transparent;
				border-right: 1rem solid transparent;
				bottom      : -1rem;
				content     : "";
				left        : 3rem;
				position    : absolute;
			}
			&.bg-primary {
				&:after {
					border-top: 1rem solid theme-color($key: "primary");
				}
			}
			&.bg-gray {
				background: $gray-200;
				&:after {
					border-top: 1rem solid $gray-200;
				}
			}
		}
	}

	&_info {
		&_avatar {
			display      : inline-block;
			margin-bottom: 1.5rem;
			max-width    : 10rem;
			img {
				width: 100%;
			}
		}
		&_name {
			color        : $gray-700;
			display      : inline-block;
			font         : 700 1.7rem $font-family-base;
			margin-bottom: 1rem;
		}
		span {
			color  : $gray-600;
			display: block;
		}
	}
	
	&_left, &_right {
		blockquote {
			&:before {
				left: 0;
				top: 0;
			}
		}
		.testimonial {
			&_info {
				@include flexbox();
				&_avatar {
					max-width   : 8rem;
				}
				&_name {
					margin-top: 1rem;
				}
			}
		}
	}

	&_left {
		.testimonial {
			&_info_avatar {
				margin-right: 2rem;
			}
		}
	}

	&_right {
		.testimonial {
			&_info_avatar {
				margin-left: 2rem;
			}
		}
	}

	&_bg {
		background     : url('../images/slider/1920x820.png') no-repeat center center;
		background-size: cover;
		color          : $white;
		padding        : 7rem 0 5rem;
		position       : relative;
		a, span {
			color: $white;
		}
		&:after {
			background: rgba(0,0,0,.5);
			content   : "";
			left      : 0;
			position  : absolute;
			top       : 0;
			@include size(100%, 100%);
		}
	}
}

/* SHORTCODES - GALLERY */
.gallery {
	&_preview {
		margin-bottom: 1rem;
	}
	&_thumbnail {
		.owl-item {
			opacity: .8;
			img {
				width: 100%;
			}
			&:hover {
				opacity: 1;
			}
			&.current {
				opacity: 1;
			}
		}
	}
}

/* SHORTCODES - PAGINATIONS */
.pagination {
	margin: 3rem 0;
	.page-item {
		margin: 0 .2rem;
		.page-link {
			background: transparent;
			border: none;
			line-height: 3.5rem;
			padding: 0;
			text-align: center;
			@include border-radius($border-radius-lg);
			@include size(3.5rem, 3.5rem);
			&:hover, &:focus {
				background: theme-color($key: "primary");
				border-color: theme-color($key: "primary");
				color: $white;
			}
		}

		&.active {
			.page-link {
				background: theme-color($key: "primary");
				border-color: theme-color($key: "primary");
				color: $white;
			}
		}
		&.disabled {
			opacity: .5;
		}
		.page-control {
			border: .1rem solid $gray-300;
		}
		> * {
			color: $gray-700;
			font-size: 1.6rem;
		}
	}

	&.pagination-sm {
		.page-item {
			> * {
				font-size  : 1.4rem;
				line-height: 2.5rem;
				@include size(2.5rem, 2.5rem);
			}
		}
	}

	&.pagination-lg {
		.page-item {
			> * {
				font-size  : 1.8rem;
				line-height: 4.5rem;
				@include size(4.5rem, 4.5rem);
			}
		}
	}
}

/* SHORTCODES - GALLERY */
.gallery_masonry {
	overflow: hidden;
	.element-item {
		width: 25%;
		&--width2 {
			width: 50%;
		}
	}
	img {
		width: 100%;
	}
}

/* COMMENTS */
#comments {
	margin-bottom: 5rem;
}
.comment {
	&_title {
		font: 400 2.5rem $font-family-base;
		margin-bottom: 4rem;
	}
	&_subtitle {
		margin-bottom: 4rem;
	}
}

.comment_respond {
	color      : $gray-700;
	font-weight: 300;
	.comment_title {
		margin-bottom: 2rem;
	}
	.form-group {
		margin-bottom: 2rem;
		textarea {
			min-height: 20rem;
			padding   : 1.5rem;
		}
		.form-control {
			font: 300 1.4rem $font-family-base;
		}
	}
}

.commentlists {
	ol {
		list-style: none;
		padding   : 0;
		> li {
			margin: 3rem 0;
			.children {
				padding-left: 5rem;
			}
		}
	}
	.comment {
		&_meta {
			margin-bottom: 1rem;
			> * {
				color       : $gray-700;
				font-weight : 300;
				margin-right: 1rem;
			}
			.avatar {
				max-width: 3.5rem;
			}
		}
		p {
			font-weight  : 300;
			margin-bottom: 2rem;
		}
		.reply {
			.btn {
				font-size     : 1rem;
				font-weight   : 300;
				padding       : .5rem 1rem;
				text-transform: capitalize;
			}
		}
	}
}

/* DESTINATION */
.destination {
	margin-bottom: 3rem;
	position     : relative;
	* {
		@include transition($transition-base);
	}
	&_thumbnail {
		position: relative;
		img {
			width: 100%;
		}
		&:after {
			background: rgba(0,0,0,.3);
			content   : "";
			left      : 0;
			opacity   : 0;
			position  : absolute;
			top       : 0;
			visibility: hidden;
			@include size(100%, 100%);
			@include transition($transition-base);
		}
	}
	&_info {
		left    : 50%;
		position: absolute;
		top     : 50%;
		@extend .text-center;
		@include translate(-50%, -50%);
		&_title {
			a {
				color         : $white;
				font          : 500 2rem $font-family-base;
				text-transform: capitalize;
			}
		}
		p {
			color: $white;
		}
	}

	@include media-breakpoint-up(lg) {
		&_info {
			p {
				opacity   : 0;
				visibility: hidden;
			}
		}
		&:hover {
			.destination_thumbnail {
				&:after {
					opacity   : 1;
					visibility: visible;
				}
			}
			.destination_info {
				p {
					opacity   : 1;
					visibility: visible;
				}
			}
		}
	}
}

/* FORM-LOGIN-REGISTER-POPUP */
.login_register_wrapper {
	.modal-header {
		.nav {
			border: none;
			width : 100%;
			&-item {
				text-align: center;
				width: 50%;
				&:first-child {
					border-right: 1px solid #ccc;
				}
				.nav-link {
					border: none;
					color: $gray-700;
					font  : 300 1.8rem $font-family-base;
					&.active {
						color: theme-color($key: "primary");
					}
				}
			}
		}
		.close {
			color    : $white;
			font-size: 2.5rem;
			opacity  : 1;
			position : absolute;
			right    : 0;
			top      : -3rem;
			&:hover {
				cursor: pointer;
			}
		}
	}
	.modal.show .modal-dialog {
		margin-top: 5rem;
	}
}

/* PRELOADER */

#preloader{
	background-color: $white;
	bottom          : 0px;
	left            : 0px;
	position        : fixed;
	right           : 0px;
	top             : 0px;
	z-index         : 99999;
	.sk-cube-grid {
		height  : 40px;
		left    : 50%;
		margin  : auto;
		position: absolute;
		top     : 50%;
		width   : 40px;
		@include translate(-50%, -50%);
		.sk-cube {
			background-color : theme-color($key: "primary");
			width            : 33%;
			height           : 33%;
			float            : left;
			@include animation(cubeGridScaleDelay 1.3s infinite ease-in-out);
			&.sk-cube1 {
				@include animation-delay(.2s);
			}
			&.sk-cube2 {
				@include animation-delay(.3s);
			}
			&.sk-cube3 {
				@include animation-delay(.4s);
			}
			&.sk-cube4 {
				@include animation-delay(.1s);
			}
			&.sk-cube5 {
				@include animation-delay(.2s);
			}
			&.sk-cube6 {
				@include animation-delay(.3s);
			}
			&.sk-cube7 {
				@include animation-delay(0s);
			}
			&.sk-cube8 {
				@include animation-delay(.1s);
			}
			&.sk-cube9 {
				@include animation-delay(.2s);
			}
		}
	}
}

@keyframes cubeGridScaleDelay {
	0% {
		@include scale3d(1, 1, 1);
	}
	35% {
		@include scale3d(0, 0, 1);
	} 
	70% {
		@include scale3d(1, 1, 1);
	}
	100% {
		@include scale3d(1, 1, 1);
	}
}

/* PRELOADER */